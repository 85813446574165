import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";
import { differenceInMonths } from "date-fns";
import { ChangeEvent, FocusEvent, useCallback, useMemo, useRef } from "react";
import { FieldValues } from "react-hook-form";
import { IRhfControl } from ".";
import { TimeFormat, useDateTimeFormatter } from "../../../hooks/useDateTimeFormatter";
import {
  getFormattedDisplayDate,
  isDateNowish,
  isDateNowOrEarlier,
  isDateToday,
  roundTimeToChunk,
} from "../../../utils/dates";
import { deepmerge } from "../../../utils/objects";
import { DateTimePicker } from "../DateTimePicker";
import { RhfControlled, RhfControlledProps, RhfControlledRenderProps } from "./RhfControlled";

const parse = (value: string, refDate?: Date, options?: ChronoParsingOption): Date | undefined => {
  if (!value) return undefined;
  return parseDate(value, refDate, { ...options });
};

export type DateControlRenderProps = MuiTextFieldProps & RhfControlledRenderProps & {};

export type DateControlProps = Omit<MuiTextFieldProps, "required"> &
  Omit<RhfControlledProps<FieldValues, DateControlRenderProps>, "render"> & {
    format?: TimeFormat;
    formatOnBlur?: boolean;
    chronoOptions?: ChronoParsingOption;
    required?: string | boolean;
    className?: string;
    defaultDatePickerValue?: string;
    defaultDatePickerHour?: ((date: Date) => Date) | number;
    disablePast?: boolean;
    disableFuture?: boolean;
    inlinePicker?: boolean;
    staticPicker?: boolean;
    dayMode?: boolean;
  };

export const DateControl: IRhfControl<DateControlProps> = ({
  rules,
  format = "DATE_DISPLAY_FORMAT",
  formatOnBlur = true,
  chronoOptions,
  required,
  className,
  helperText,
  defaultDatePickerValue,
  defaultDatePickerHour,
  disablePast = true,
  disableFuture,
  inlinePicker,
  staticPicker,
  dayMode,
  ...rest
}) => {
  const anchorElRef = useRef<HTMLInputElement | undefined>();

  const { format: timeFormatFn } = useDateTimeFormatter();

  const parseFn = useCallback(
    (value: string) => {
      if (!!chronoOptions?.forwardDate && !!disablePast) {
        const withOptions = parse(value, undefined, chronoOptions);
        const withoutOptions = parse(value);

        // This is to prevent the date jumping multiple years forward when looking up a date.
        const parsed =
          withOptions && withoutOptions && Math.abs(differenceInMonths(withOptions, withoutOptions)) > 13
            ? withoutOptions
            : withOptions;

        return parsed && isDateNowOrEarlier(parsed) ? new Date() : parsed;
      } else {
        return parse(value, undefined, chronoOptions);
      }
    },
    [chronoOptions, disablePast]
  );

  const merged: DateControlProps["rules"] = useMemo(
    () =>
      deepmerge({}, rules, {
        required,
        validate: {
          parseable: (v: string) => !v || !!parseFn(v) || "invalid date",
        },
      }),
    [rules, required, parseFn]
  );

  const formatFn = useCallback(
    (date: Date, dayMode: boolean) => getFormattedDisplayDate(date, format, timeFormatFn, dayMode),
    [format, timeFormatFn]
  );

  const getDisplayDate = useCallback(
    (date: Date) => {
      if (!!dayMode) {
        return isDateToday(date) ? "Today" : formatFn(date, true);
      } else {
        return isDateNowish(date) ? "now" : formatFn(roundTimeToChunk(date), false);
      }
    },
    [dayMode, formatFn]
  );

  const render = useCallback(
    ({ field, fieldState, formState, ...rest }: DateControlRenderProps) => {
      const { name, value, ref: inputRef, onChange, onBlur } = field;

      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        rest.onChange?.(e);
      };

      const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const parsed = parseFn(e.target.value);

        // RAI-4082: The rhf onBlur triggers an onChange event that will prematurely set the
        // value in the date picker. If the parsed is undefined and the current value is "", this
        // can cause the month to jump on the user before the click finishes. This is essentially a no
        // op so it is fine if rhf doesn't get the change here.
        if (parsed === undefined && value === "") return;

        // input is a valid date
        if (!!parsed && formatOnBlur) {
          const display = getDisplayDate(parsed);
          // Per comment above also return if value hasn't changed.
          if (display === value) return;

          onChange(display);
        }

        onBlur();
        rest.onBlur?.(e);
      };

      const handlePickerChange = (name: string, date: Date) => {
        if (!!defaultDatePickerHour) {
          if (typeof defaultDatePickerHour === "function") {
            date = defaultDatePickerHour(date);
          } else {
            date.setHours(defaultDatePickerHour);
            date.setMinutes(0);
            date.setSeconds(0);
          }
        }

        const pickerValue = getDisplayDate(date);

        /**
         * The date picker selection is triggered after the native input events are triggered. This causes
         * validation timing issues with the new input value. The following synthetically triggers the value
         * being set in the input (triggers handleChange above). Blur is called before rhf knows of the change so we
         * need the blur event to trigger again for rhf to have the correct value before triggering blur logic.
         */
        const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
        valueSetter?.call(anchorElRef.current, pickerValue);

        const syntheticChange = new Event("input", { bubbles: true });
        anchorElRef.current?.dispatchEvent(syntheticChange);

        anchorElRef.current?.focus();
        setTimeout(() => anchorElRef.current?.blur(), 10);
      };

      const datePickerValue = parseFn(!value && defaultDatePickerValue ? defaultDatePickerValue : value);

      return (
        <div className={className}>
          <MuiTextField
            variant="standard"
            {...rest}
            inputRef={(el) => {
              // Set the anchor ref and then trigger the inputRef
              // callback. RHF needs this el and the DateTimePicker.
              if (!anchorElRef.current) {
                anchorElRef.current = el;
              }

              inputRef(anchorElRef.current);
            }}
            {...{ name, value, onChange: handleChange, onBlur: handleBlur }}
            error={!!fieldState.error}
            helperText={!!fieldState.error?.message ? fieldState.error?.message : helperText}
          />

          {/* TODO: (SS) Likely will need an option to not show the date picker in mobile? */}
          <DateTimePicker
            anchorEl={anchorElRef}
            value={datePickerValue}
            onChange={handlePickerChange}
            inlinePicker={inlinePicker}
            staticPicker={staticPicker}
            disablePast={disablePast}
            disableFuture={disableFuture}
          />
        </div>
      );
    },
    [
      defaultDatePickerValue,
      className,
      helperText,
      inlinePicker,
      staticPicker,
      disablePast,
      disableFuture,
      formatOnBlur,
      getDisplayDate,
      defaultDatePickerHour,
      parseFn,
    ]
  );

  return <RhfControlled {...rest} rules={merged} render={render} />;
};

DateControl.isControl = true;
DateControl.isController = true;
